* {
  box-sizing: border-box;
}

.wrapper {
  /* color: #fff!important; */
  width: 90%;
  display: block;
  margin: auto;
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.accordion {
  width: 100%;
  box-shadow: 0mm;
  /* background-color: #F4F4F2; */
}

.accSumm {
  background-color: #F4F4F2;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  padding: 3px;
}

.gridContainer {
  width: 100%;
  height: 100%;
  /* border: 2px solid green; */
  text-align: center;
}

.divider {
  margin: 10px auto;
}

.copyIcon {
  cursor: pointer;
}

.copyIcon:hover {
  background-color: #707070;
}

.card {
  /* border: 2px solid green; */
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
    0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 8px 10px -5px rgba(0, 0, 0, 0.2);
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 8px 25px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: space-around;
  align-items: center;
}

.smallFont {
  font-size: 14px !important;
  color: #000;
}

.card:hover {
  background-color: #707070;
}

.create-endpoint-btn {
  margin-top: 50px;
  background-color: #E8E8E8;
  padding: 1px 0;
  border-radius: 8px;
  /* width: 200px; */
}

.create-endpoint-btn h3 {
  text-align: center;
  cursor: pointer;
  color: #403F3F;
  padding: 15px 15px;

  /* font-weight: 500; */
}

.create-endpoint-btn a {
  text-decoration: none;
  ;
}

.create-new {
  margin-top: 100px;
}

.endpoint-header h3 {
  text-align: center;
}

.endpoint-header {
  text-align: center;
  /* padding-bottom: 30px; */
}

.endpoint-header h4 {
  text-transform: uppercase;
  font-size: 25px;
}

.node-title {
  font-size: 22px;
  color: #403f3f;
  padding: 0;
  width: -webkit-max-content;
  width: max-content;
  text-transform: capitalize;
  width: 335px;
  margin-left: 6px;
  text-align: left;
}

.node-title-container {
  display: flex;
  justify-content: space-between;
  height: 210px;
}

.wrapper a {
  text-decoration: none;
  color: #000;
}

.wrapper h4 {
  color: #726e6e;
  margin: 6px;
}


.form-header {
  color: white !important;
  background-color: black;
}

.form-button {
  background-color: #000 !important;
  color: white !important;
}

.form-button-disable {
  opacity: 0.4;
  background-color: #000 !important;
  color: white !important;
}

.css-wjsjww-MuiChip-label {
  color: white !important;
}

.sm-Scrollbar::-webkit-scrollbar {
  height: 6px; 
}
.sm-Scrollbar::-webkit-scrollbar-thumb {
  background: #888; 
}
.sm-Scrollbar::-webkit-scrollbar-thumb:hover{
  background-color: #000;
}

.sm-y-Scrollbar::-webkit-scrollbar {
  width: 8px; 
}
.sm-y-Scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background: #888; 
}
.sm-y-Scrollbar::-webkit-scrollbar-thumb:hover{
  background-color: #000;
}


.shadow-btn:hover{
  box-shadow: 0px 3px 6px 2px #c1c1c1;
}
.time-status{
  color: #9d9d9d !important;
  border-radius: 20px;
  width: 190px;
}
.time-status>span{
  color: #9d9d9d !important;
}